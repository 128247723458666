import { LoadingButton } from '@mui/lab';
import { ScaleLoader } from 'react-spinners';

interface ButtonInterface {
  text: string | any;
  color?: string | any;
  endIcon?: any;
  startIcon?: any;
  className?: string | undefined;
  iconClassName?: string | undefined;
  startIconClassName?: string | undefined;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined';
  onClick?: any;
  type?: 'button' | 'submit' | undefined;
  loading?: boolean;
  fontSize?: number | undefined;
  fontWeight?: number | undefined;
  disabled?: boolean | undefined;
  showIcon?: boolean | undefined;
  textAlign?: any;
  width?: any;
  height?: any;
  sx?: any; // Add sx prop to support MUI styling
  style?: any; // Add direct style prop
  hoverStyle?: any; // Add hover style prop
  disabledStyle?: any; // Add disabled style prop
}

const CustomButton = ({
  text,
  size,
  onClick,
  variant = 'contained',
  color,
  className,
  startIcon,
  endIcon,
  iconClassName,
  startIconClassName,
  type = 'button',
  loading = false,
  fontSize = 14,
  showIcon = true,
  fontWeight,
  disabled = false,
  textAlign,
  width,
  height,
  sx,
  style,
  hoverStyle,
  disabledStyle
}: ButtonInterface) => {
  const StartIcon = startIcon;
  const EndIcon = endIcon;
  
  // Combine styles
  const buttonStyle = {
    fontSize,
    fontWeight,
    textAlign,
    width,
    height,
    ...style
  };
  
  // Combine sx props
  const combinedSx = {
    '&:hover': hoverStyle,
    '&.Mui-disabled': disabledStyle,
    ...sx
  };

  return (
    <LoadingButton
      size={size}
      onClick={onClick}
      variant={variant}
      color={color}
      className={className}
      startIcon={
        showIcon && StartIcon ? (
          <StartIcon className={startIconClassName} />
        ) : null
      }
      endIcon={
        showIcon && EndIcon ? (
          <EndIcon className={iconClassName} />
        ) : null
      }
      type={type}
      disabled={disabled}
      loading={loading}
      loadingIndicator={<ScaleLoader color="#ffffff" height={15} />}
      disableRipple
      style={buttonStyle}
      sx={combinedSx}
    >
      {loading ? '' : text}
    </LoadingButton>
  );
};

export default CustomButton;