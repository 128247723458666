import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from '../../common';
import { INITIAL_STATE_TYPE } from "../../models/CommonModels";
import { PostModel } from "../../models/PostModels";
import { RootState } from "../store";

interface PostDetailsTypes extends INITIAL_STATE_TYPE {
    data: null | PostModel;
}

interface PostListTypes extends INITIAL_STATE_TYPE {
    data: PostModel[];
}

interface InitialStateTypes {
    postList: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    savePost: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    updatePost: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    deletePost: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | null;
    };
}

const initialState: InitialStateTypes = {
    postList: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    savePost: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    updatePost: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    deletePost: {
        loading: false,
        success: false,
        error: false,
        message: null,
    },
};

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        postListInProgress: (state) => {
            console.log(state,"state")
            state.postList.status = 'loading';
            state.postList.data = undefined;
            state.postList.error = undefined;
        },
        postListInSuccess: (state, { payload }) => {
            state.postList.status = 'done';
            state.postList.data = payload.data;
            state.postList.error = undefined;
            state.postList.message = payload.message;
        },
        postListInError: (state, { payload }) => {
            state.postList.status = 'error';
            state.postList.data = undefined;
            state.postList.error = payload;
            state.postList.message = payload.message;
        },
        savePostRequest: (state, { payload }) => {
            state.savePost.status = 'loading';
            state.savePost.data = payload?.data;
            state.savePost.error = undefined;
        },
        savePostSuccess: (state, { payload }) => {
            state.savePost.status = 'done';
            state.savePost.data = payload?.data;
            state.savePost.error = undefined;
            state.savePost.message = payload?.message;
        },
        savePostError: (state, { payload }) => {
            state.savePost.status = 'error';
            state.savePost.data = undefined;
            state.savePost.error = payload;
            state.savePost.message = payload?.message;
        },
        updatePostRequest: (state) => {
            state.updatePost.status = 'loading';
            state.updatePost.data = null;
            state.updatePost.error = null;
            state.updatePost.message = null;
        },
        updatePostSuccess: (state, { payload }: PayloadAction<{ data: PostModel; message: string }>) => {
            state.updatePost.status = 'done';
            state.updatePost.data = payload.data;
            state.updatePost.message = payload.message;
            state.updatePost.error = null;
        },
        updatePostError: (state, { payload }: PayloadAction<{ message: string }>) => {
            state.updatePost.status = 'error';
            state.updatePost.data = null;
            state.updatePost.error = payload.message;
            state.updatePost.message = null;
        },
        deletePostInProgress: (state, {}: PayloadAction<{ data: any; message: string }>) => {
            state.deletePost.loading = true;
            state.deletePost.success = false;
            state.deletePost.error = false;
            state.deletePost.message = null;
        },
        deletePostInSuccess: (state, { payload }: PayloadAction<{ data: any; message: string }>) => {
            state.deletePost.loading = false;
            state.deletePost.success = true;
            state.deletePost.message = payload.message;
        },
        deletePostInError: (state, { payload }: PayloadAction<{ message: string }>) => {
            state.deletePost.loading = false;
            state.deletePost.error = true;
            state.deletePost.message = payload.message;
        },
        postDefaultData: (state) => {
            state.savePost.status = 'loading';
            state.savePost.data = undefined;
            state.savePost.error = undefined;
        },
    },
});

export const {
    postListInProgress,
    postListInSuccess,
    postListInError,
    savePostRequest,
    savePostSuccess,
    savePostError,
    postDefaultData,
    updatePostRequest,
    updatePostSuccess,
    updatePostError,
    deletePostInProgress,
    deletePostInSuccess,
    deletePostInError,
} = postSlice.actions;

export const selectPostListLoading = (state: RootState) =>
    state.post.postList.status === 'loading'

export const selectPostListInfo = (state: RootState) =>
    state.post.postList.data;

export const selectPostListError = (state: RootState) =>
    state.post.postList.error;

export const savePostSuccessData = (state: RootState) =>
    state.post.savePost.status;

export const savePostUpdateData = (state: RootState) =>
    state.post.updatePost.status;

export default postSlice;