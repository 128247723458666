import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import CustomButton from '../../../../common/ui/CustomButton';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import DeleteModel from '../../../../common/ui/Modal/DeleteModel';
import TableComponent from '../../../../common/ui/TableComponent';
import {
	departmentDefaultData,
	departmentListInProgress,
	selectDepartmentListInfo,
	selectDepartmentListLoading,
} from '../../../../store/Departnment/_department.slice';
import '../candidate.scss';
import DepartmentHeader from './DepartnmentHeader';

const DepartmentList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [veiwOpen, setVeiwOpen] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [deleteData, setDeleteData] = useState(false);
	const publicList = useSelector(selectDepartmentListInfo);
	const publicListLoading = useSelector(selectDepartmentListLoading);
	const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
	const [openAddDialog, setOpenAddDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openViewDialog, setOpenViewDialog] = useState(false);
	interface Department {
		id: number;
		name: string;
	  }
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm();

	const handleAdd = () => {
		setOpenAddDialog(true);
	};
	const handleEdit = () => {
		setOpenEditDialog(true);
	};
	const handleCloseAddDialog = () => {
		setOpenAddDialog(false);
		reset();
	};
	const handleCloseEditDialog = () => {
		setOpenEditDialog(false);
		reset();
	};
	const handleCloseViewDialog = () => {
		setOpenViewDialog(false);
	};

	const handleAddDepartment = (data: any) => {
		if (data.departmentName.trim() === '') {
			return;
		}

		// dispatch(addDepartment(data.departmentName));

		handleCloseAddDialog();
	};
const handleEditDepartment = (data: any) => {
	if (data.departmentName.trim() === '') {
		return;
		}
		// dispatch(editDepartment(data.departmentName, data.id));
		handleCloseEditDialog();
		};

	const columns: GridColDef[] = [
		{
			headerName: t('SERIALNUMBER'),
			field: 'id',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('DEPARTMENTNAME'),
			field: 'name',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: 'Action',
			field: 'Action',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
			width: 350,
			renderCell: ({ row }) => {
				const handleEditClick = () => {
					setSelectedDepartment(row);
					reset({ departmentName: row.name }); 
					handleEdit();
				};

				const handleViewClick = () => {
					setOpenViewDialog(true);
					setSelectedDepartment(row); 
				};

				const handleDeleteClick = () => {
					setOpenDeleteModal(true);
					setDeleteId(row.id);
				};

				return (
					<>
						<CustomButton
							text={'Edit'}
							className="mx-3  color-gray"
							onClick={handleEditClick}
						/>
						<CustomButton
							text={'View'}
							className="mx-3"
							color={'warning'}
							onClick={handleViewClick}
						/>

						<CustomButton
							text={'Delete'}
							className="mx-3"
							onClick={handleDeleteClick}
							color={'warning'}
						/>
					</>
				);
			},
		},
	];

	useEffect(() => {
		if (deleteData) {
			// handleDelete();
		}
	}, [deleteData]);

	useEffect(() => {
		const payload = {
			pageNo: 1,
			pagination_required: true,
		};
		dispatch(departmentListInProgress(payload as any));
		dispatch(departmentDefaultData());
	}, [dispatch]);

	useEffect(() => {
		console.log('Department List:', publicList);
	}, [publicList]);

	useEffect(() => {
		setLoading(publicListLoading);
	}, [publicListLoading]);

	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);

	const handlePagination = (data: any) => {
		const payloads = {
			canditate_id: decrptionUser?.candidate_id || '',
			people_id: decrptionUser?.people_id || '',
			pageNo: data?.page + 1,
			pagination_required: true,
		};
		dispatch(departmentListInProgress(payloads as any));
	};

	return (
		<Grid container my={5}>
			<Grid xs={12}>
				<DepartmentHeader view={veiwOpen} />
			</Grid>
			{loading ? (
				<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
					<CircularIndeterminate />
				</Grid>
			) : (
				<Grid xs={12} my={5}>
					<TableComponent
						rows={publicList?.data ? publicList?.data : []}
						columns={columns}
						type="public"
						totalRecords={publicList?.totalRecords || 0}
						handlePagination={handlePagination}
						paginationModel={{
							page: Number(publicList?.currentPage) - 1 || 0,
							pageSize: Number(publicList?.pagesize) || 5,
						}}
						handleAdd={handleAdd}
					/>
				</Grid>
			)}
			<Dialog open={openAddDialog} maxWidth="sm" fullWidth>
				<DialogTitle>{t('ADD_DEPARTMENT')}</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit(handleAddDepartment)}>
						<FormInput
							placeholder={`${t('DEPARTMENT_NAME')}...`}
							className="username-input"
							name="departmentName"
							type="text"
							control={control}
							rules={{
								required: t('PLEASE_ENTER_DEPARTMENT_NAME'),
							}}
							inputProps={{
								className: 'text-center',
								...register('departmentName'),
							}}
							errors={errors.departmentName && errors.departmentName.message}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<CustomButton
						text={t('CANCEL')}
						color={'warning'}
						onClick={handleCloseAddDialog}
					/>
					<CustomButton
						type="submit"
						text={t('SUBMIT')}
						color="warning"
						className="mx-3  color-gray"
						onClick={handleSubmit(handleAddDepartment)}
					/>
				</DialogActions>
			</Dialog>
			<Dialog open={openEditDialog} maxWidth="sm" fullWidth>
				<DialogTitle>{t('UPDATE_DEPARTMENT')}</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit(handleEditDepartment)}>
						<FormInput
							placeholder={`${t('DEPARTMENT_NAME')}...`}
							className="username-input"
							name="departmentName"
							type="text"
							control={control}
							rules={{
								required: t('PLEASE_ENTER_DEPARTMENT_NAME'),
							}}
							inputProps={{
								className: 'text-center',
								...register('departmentName'),
							}}
							errors={errors.departmentName && errors.departmentName.message}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<CustomButton
						text={t('CANCEL')}
						color={'warning'}
						onClick={handleCloseEditDialog}
					/>
					<CustomButton
						type="submit"
						text={t('SUBMIT')}
						color="warning"
						className="mx-3 color-gray"
						onClick={handleSubmit(handleEditDepartment)}
					/>
				</DialogActions>
			</Dialog>
			<Dialog
  open={openViewDialog}
  onClose={handleCloseViewDialog}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle>{t('VIEW_DEPARTMENT')}</DialogTitle>
  <DialogContent>
  {selectedDepartment && (
  <>
    <Grid item xs={12}>
      <strong>{t('SERIALNUMBER')}:</strong> {(selectedDepartment as Department).id || 'null'}
    </Grid>
    <Grid item xs={12}>
      <strong>{t('DEPARTMENTNAME')}:</strong> {(selectedDepartment as Department).name || 'null'}
    </Grid>
  </>
)}

  </DialogContent>
  <DialogActions>
    <CustomButton
      text={t('CLOSE')}
      color={'warning'}
      onClick={handleCloseViewDialog}
    />
  </DialogActions>
</Dialog>
			<DeleteModel
				setOpen={setOpenDeleteModal}
				open={openDeleteModal}
				handleDelete={''}
				setDeleteData={setDeleteData}
			/>
		</Grid>
	);
};

export default DepartmentList;
