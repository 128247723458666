import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
    addTalukaEffect,
    deleteTalukaEffect,
    getTalukaListEffect,
    updateTalukaEffect
} from "./_talukas.service";
import {
    deleteTalukaInError,
    deleteTalukaInProgress,
    deleteTalukaInSuccess,
    saveTalukaError,
    saveTalukaRequest,
    saveTalukaSuccess,
    talukaListInError,
    talukaListInProgress,
    talukaListInSuccess,
    updateTalukaError,
    updateTalukaRequest,
    updateTalukaSuccess,
} from "./_talukas.slice";

function* getTalukasListWatcher({ payload }: any) {
    try {
        const { data } = yield call(getTalukaListEffect, payload);
        console.log('Fetched data:', data);
        yield put(talukaListInSuccess({ data, message: 'Success' }));
    } catch (error) {
        console.error('Error fetching taluka list:', error);
        yield put(talukaListInError({ message: "error" }));
    }
}

function* saveTalukaWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000); 
        const { data } = yield call(addTalukaEffect, payload);
        yield put(saveTalukaSuccess({ data , message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(saveTalukaError({ message:"error" }));
    }
}

function* editTalukaWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000);
        const { data } = yield call(updateTalukaEffect, payload);
        yield put(updateTalukaSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(updateTalukaError({ message:"error"}));
    }
}

function* deleteTalukaWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000);
        const { data } = yield call(deleteTalukaEffect, payload);
        yield put(deleteTalukaInSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(deleteTalukaInError({ message:"error"}));
    }
}

export default function* TalukasSaga() {
    yield takeLatest(talukaListInProgress.type, getTalukasListWatcher);
    yield takeLatest(saveTalukaRequest.type, saveTalukaWatcher);
    yield takeLatest(updateTalukaRequest.type, editTalukaWatcher);
    yield takeLatest(deleteTalukaInProgress.type, deleteTalukaWatcher);
}