import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from '../../common';
import { INITIAL_STATE_TYPE } from "../../models/CommonModels";
import { DepartmentModel } from "../../models/DepartmentModels";
import { RootState } from "../store";

interface DepartmentDetailsTypes extends INITIAL_STATE_TYPE {
    data: null | DepartmentModel;
}

interface DepartmentListTypes extends INITIAL_STATE_TYPE {
    data: DepartmentModel[];
}

interface InitialStateTypes {
    departmentList: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    saveDepartment: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    updateDepartment: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    deleteDepartment: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | null;
    };
}

const initialState: InitialStateTypes = {
    departmentList: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    saveDepartment: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    updateDepartment: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    deleteDepartment: {
        loading: false,
        success: false,
        error: false,
        message: null,
    },
};

const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        departmentListInProgress: (state) => {
            state.departmentList.status = 'loading';
            state.departmentList.data = undefined;
            state.departmentList.error = undefined;
        },
        departmentListInSuccess: (state, { payload }) => {
            state.departmentList.status = 'done';
            state.departmentList.data = payload.data;
            state.departmentList.error = undefined;
            state.departmentList.message = payload.message;
        },
        departmentListInError: (state, { payload }) => {
            state.departmentList.status = 'error';
            state.departmentList.data = undefined;
            state.departmentList.error = payload;
            state.departmentList.message = payload.message;
        },
        saveDepartmentRequest: (state) => {
            state.saveDepartment.status = 'loading';
            state.saveDepartment.data = undefined;
            state.saveDepartment.error = undefined;
        },
        saveDepartmentSuccess: (state, { payload }) => {
            state.saveDepartment.status = 'done';
            state.saveDepartment.data = payload.data;
            state.saveDepartment.error = undefined;
            state.saveDepartment.message = payload.message;
        },
        saveDepartmentError: (state, { payload }) => {
            state.saveDepartment.status = 'error';
            state.saveDepartment.data = undefined;
            state.saveDepartment.error = payload;
            state.saveDepartment.message = payload.message;
        },
        updateDepartmentRequest: (state) => {
            state.updateDepartment.status = 'loading';
            state.updateDepartment.data = undefined;
            state.updateDepartment.error = undefined;
        },
        updateDepartmentSuccess: (state, { payload }) => {
            state.updateDepartment.status = 'done';
            state.updateDepartment.data = payload.data;
            state.updateDepartment.error = undefined;
            state.updateDepartment.message = payload.message;
        },
        updateDepartmentError: (state, { payload }) => {
            state.updateDepartment.status = 'error';
            state.updateDepartment.data = undefined;
            state.updateDepartment.error = payload;
            state.updateDepartment.message = payload.message;
        },
        deleteDepartmentInProgress: (state, {}: PayloadAction<{ data: any; message: string }>) => {
            state.deleteDepartment.loading = true;
            state.deleteDepartment.success = false;
            state.deleteDepartment.error = false;
            state.deleteDepartment.message = null;
        },
        deleteDepartmentInSuccess: (state, { payload }: PayloadAction<{ data: any; message: string }>) => {
            state.deleteDepartment.loading = false;
            state.deleteDepartment.success = true;
            state.deleteDepartment.message = payload.message;
        },
        deleteDepartmentInError: (state, { payload }: PayloadAction<{ message: string }>) => {
            state.deleteDepartment.loading = false;
            state.deleteDepartment.error = true;
            state.deleteDepartment.message = payload.message;
        },
        departmentDefaultData: (state) => {
            state.saveDepartment.status = 'loading';
            state.saveDepartment.data = undefined;
            state.saveDepartment.error = undefined;
        },
    },
});

export const {
    departmentListInProgress,
    departmentListInSuccess,
    departmentListInError,
    saveDepartmentRequest,
    saveDepartmentSuccess,
    saveDepartmentError,
    departmentDefaultData,
    updateDepartmentRequest,
    updateDepartmentSuccess,
    updateDepartmentError,
    deleteDepartmentInProgress,
    deleteDepartmentInSuccess,
    deleteDepartmentInError,
} = departmentSlice.actions;

export const selectDepartmentListLoading = (state: RootState) =>
    state.department.departmentList.status === 'loading';

export const selectDepartmentListInfo = (state: RootState) =>
    state.department.departmentList.data;
console.log(selectDepartmentListInfo,"selectDepartmentListInfo")
export const selectDepartmentListError = (state: RootState) =>
    state.department.departmentList.error;

export const saveDepartmentSuccessData = (state: RootState) =>
    state.department.saveDepartment.status;

export default departmentSlice;