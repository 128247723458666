import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
    addPosts,
    deletePostReq,
    postList,
    updatePosts
} from './_post.service';
import {
    deletePostInError,
    deletePostInProgress,
    deletePostInSuccess,
    postListInError,
    postListInProgress,
    postListInSuccess,
    savePostError,
    savePostRequest,
    savePostSuccess,
    updatePostError,
    updatePostRequest,
    updatePostSuccess,
} from './_post.slice';


function* getPostsListWatcher({ payload }: any) {
    try {
        const { data } = yield call(postList, payload);
        console.log('Fetched data:', data);
        yield put(postListInSuccess({ data, message: 'Success' }));
    } catch (error) {
        console.error('Error fetching post list:', error);
        yield put(postListInError({ message: "error" }));
    }
}

// function* getPostDetailsWatcher({ payload }: ReturnType<typeof postDetailsInProgress>) {
//     try {
//         yield delay(3000); 
//         const { data } = yield call(viewPostEffect, payload);
//         yield put(postDetailsInSuccess({ data, message: 'Success' }));
//     } catch (error) {
//         yield put(postDetailsInError({ message:"error" }));
//     }
// }


function* savePostWatcher({ payload: { handleSuccess, ...payload } }: any) {
    console.log(payload,"saga")
    try {
        // yield delay(3000); 
        const { data } = yield call(addPosts, payload);
        yield put(savePostSuccess({ data , message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(savePostError({ message:"error" }));
    }
}


function* editPostWatcher({ payload: { handleSuccess, ...payload } }: any) {
    console.log(payload,"saga8998")
    try {
        // yield delay(3000);
        const { data } = yield call(updatePosts, payload);
        console.log(data,"data");
        yield put(updatePostSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(updatePostError({ message:"error"}));
    }
}

function* deletePostWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000);
        const { data } = yield call(deletePostReq, payload);
        yield put(deletePostInSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(deletePostInError({ message:"error"}));
    }
}


// Root saga for posts
export default function* PostsSaga() {
    yield takeLatest(postListInProgress.type, getPostsListWatcher);
    // yield takeLatest(postDetailsInProgress.type, getPostDetailsWatcher);
    yield takeLatest(savePostRequest.type, savePostWatcher);
    yield takeLatest(updatePostRequest.type, editPostWatcher);
    yield takeLatest(deletePostInProgress.type, deletePostWatcher);
}