/* eslint-disable react/require-default-props */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	useTheme,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './index.scss';

interface FormInputInterface {
	size?: 'small' | 'medium';
	disabled?: boolean;
	fullWidth?: boolean;
	label?: string | undefined;
	placeholder?: string | undefined;
	type?: string | undefined;
	className?: string | undefined;
	labelClassName?: string | undefined;
	spacing?: number | undefined;
	inputProps?: any;
	backgroundColor?: string | undefined;
	hideCountButton?: boolean | undefined;
	invertBorder?: boolean | undefined;
	errors?: any;
	errorTextClassName?: string | undefined;
	hidden?: boolean | undefined;
	control?: any;
	name?: string | undefined;
	rules?: any;
	readOnly?: boolean | undefined;
	style?: any;
	multipleLine?: boolean | undefined;
	onClick?: any;
	onChange?:any
	onFocus?: any;
	defaultValue?: any;
	handleCopy?: any;
	handlePast?: any;
	handleCut?: any;
	autocomplete?: any;
	contentEditable?: any;
	classNameFormGrid?: any;
	startAdornment?: JSX.Element;
}
const FormInput = ({
	disabled = false,
	fullWidth = false,
	label = '',
	placeholder = ' ',
	size = 'small',
	type = 'text',
	className,
	labelClassName,
	spacing,
	inputProps = {},
	backgroundColor,
	hideCountButton = false,
	invertBorder = false,
	errors = {},
	errorTextClassName = '',
	hidden = false,
	control = false,
	name = 'test',
	rules = {},
	readOnly = false,
	style,
	multipleLine = false,
	onClick,
	onChange,
	onFocus,
	defaultValue,
	handleCopy,
	handlePast,
	handleCut,
	autocomplete,
	contentEditable,
	classNameFormGrid,
	startAdornment,
}: FormInputInterface) => {
	const theme = useTheme();
	const { control: childControl } = useForm();
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show: any) => !show);
	return (
		<Grid
			className={`form-input-container ${
				hidden && 'hidden'
			} ${classNameFormGrid} `}
			container
			flexDirection="column"
			spacing={spacing}
		>
			<Grid item>
				<InputLabel variant="standard" className={`${labelClassName}`}>
					{label}
				</InputLabel>
			</Grid>
			<Grid item>
				<Controller
					name={name}
					control={control || childControl}
					rules={rules}
					defaultValue={defaultValue}
					render={({ field: { value, onChange, onBlur, ...field } }) => (
						<OutlinedInput
							style={style}
							multiline={multipleLine}
							className={`form-input w-100 ${className} ${
								hideCountButton ? 'hide-count-button' : ''
							} ${invertBorder ? 'invert-border' : 'normal-border'} ${
								multipleLine ? 'multiple-line' : ''
							}`}
							fullWidth={fullWidth}
							size={size}
							placeholder={placeholder}
							type={
								type === 'password'
									? showPassword
										? 'text'
										: 'password'
									: type
							}
							endAdornment={
								type === 'password' && (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}
							disabled={disabled}
							onBlur={onBlur}
							onChange={onChange}
							inputProps={{ ...field, ...inputProps }}
							value={value}
							sx={{
								backgroundColor: backgroundColor || theme.palette.common.white,
							}}
							error={!!errors?.[name]}
							readOnly={readOnly}
							onClick={onClick}
							onFocus={onFocus}
							onCut={handleCut}
							onCopy={handleCopy}
							onPaste={handlePast}
							autoComplete={autocomplete}
							contentEditable={contentEditable}
						/>
					)}
				/>

				{errors?.[name]?.message && (
					<FormHelperText
						className={`error-text ${errorTextClassName}`}
						error={false}
						sx={{ color: theme.palette.error.dark }}
					>
						{errors?.[name]?.message}
					</FormHelperText>
				)}
			</Grid>
		</Grid>
	);
};

export default FormInput;
