import { all } from 'redux-saga/effects';
import authSaga from './auth/_auth.saga';
import CandidatesSaga from './candidates/_candidates.saga';
import ConstituenciesSaga from './constituencies/_constituencies.saga';
import DepartmentsSaga from './Departnment/_department.saga';
import PartiesSaga from './parties/_parties.saga';
import petetionSaga from './petetions/_petetion.saga';
import PostsSaga from './Post/_post.saga';
import PricingPlansSaga from './pricingPlans/_pricingPlans.saga';
import profileSaga from './profile/profile.saga';
import publicSaga from './public/_public.saga';
import TalukasSaga from './talukas/_talukas.saga';

export default function* rootSaga() {
	yield all([
		CandidatesSaga(),
		PartiesSaga(),
		ConstituenciesSaga(),
		authSaga(),
		publicSaga(),
		petetionSaga(),
		profileSaga(),
		PricingPlansSaga(),
		DepartmentsSaga(),
		TalukasSaga(),
		PostsSaga()
	]);
	// code after all-effect
}
