import { Instance } from '../../common/axiosInstance';

const token = localStorage.getItem('accessToken');

export const getDepartmentListEffect = (payload: any) => {
  return Instance.request({
    url: `/departments/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const addDepartmentEffect = (payload: any) => {
  return Instance.request({
    url: '/departments/add',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: payload,
  });
};

export const updateDepartmentEffect = (payload: any) => {
  return Instance.request({
    url: '/departments/update',
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: payload,
  });
};

export const viewDepartmentEffect = (payload: any) => {
  return Instance.request({
    url: `/departments/view/${payload.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};


export const deleteDepartmentEffect = (payload: any) => {
  return Instance.request({
    url: `/departments/delete/${payload.id}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};
