// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation } from 'react-router-dom';
import '../candidate.scss';
import { AddPostForm } from "./AddPost";

const EditPost = () => {
	const location = useLocation();
	const editData = location?.state?.data?.data;
	console.log(editData,"editData")
	const dataType = location?.state?.data?.type;
    return <AddPostForm editData={editData} dataType={dataType} />;
};

export default EditPost;
