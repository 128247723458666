import { combineSlices } from '@reduxjs/toolkit';
import commonSlice from '../common/Common.slice';
import authSlice from './auth/_auth.slice';
import CandidateSlice from './candidates/_candidates.slice';
import ConstituenciesSlice from './constituencies/_constituencies.slice';
import departmentSlice from './Departnment/_department.slice';
import PartieSlice from './parties/_parties.slice';
import PetetionSlice from './petetions/_petetion.slice';
import postSlice from './Post/_post.slice';
import PricingPlanSlice from './pricingPlans/_pricingPlans.slice';
import profileSlice from './profile/_profile.slice';
import publicSlice from './public/_public.slice';
import talukaSlice from "./talukas/_talukas.slice";
const rootReducer = combineSlices(
	CandidateSlice,
	PartieSlice,
	ConstituenciesSlice,
	authSlice,
	commonSlice,
	publicSlice,
	PetetionSlice,
	profileSlice,
	departmentSlice,
	commonSlice,
	PricingPlanSlice,
	postSlice,
	talukaSlice
);   

export default rootReducer;
