import { Box, Chip, Divider, Grid, Paper, Typography, alpha, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import CustomButton from '../../../../common/ui/CustomButton';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import {
    savePostRequest,
    savePostSuccessData,
    savePostUpdateData,
    updatePostRequest,
} from '../../../../store/Post/_post.slice';

// Import icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageIcon from '@mui/icons-material/Image';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import ShortTextIcon from '@mui/icons-material/ShortText';
import TitleIcon from '@mui/icons-material/Title';
import WarningIcon from '@mui/icons-material/Warning';

const MAX_FILE_SIZE = 200 * 1024; 
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

export const AddPostForm = ({
    editData,
    dataType,
}:any) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [featuredImageBase64, setFeaturedImageBase64] = useState('');
    const [featuredImageName, setFeaturedImageName] = useState('');
    const [postImageBase64, setPostImageBase64] = useState('');
    const [postImageName, setPostImageName] = useState('');
    const [fileError, setFileError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const addPublicSuccess = useSelector(savePostSuccessData);
    const addPrivateSuccess = useSelector(savePostUpdateData);
    const userInfo = localStorage.getItem('userInfo');
    const decrptionUser = JSON.parse(
        cryptoEncryptionDecryption.Decrypt(userInfo)
    );

    const primaryColor = theme.palette.primary.main;
    const warningColor = theme.palette.warning.main;
    const successColor = theme.palette.success.main;
    const errorColor = theme.palette.error.main;
    const isEditMode = dataType === 'edit' || location?.state?.data?.type === 'edit';

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        control,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            title: editData?.title || '',
            short_description: editData?.short_description || '',
            description: editData?.description || '',
            featured_image: editData?.featured_image || '',
            post_image: editData?.post_image || '',
        },
    });

    // Watch form fields to show preview card
    const watchTitle = watch('title');
    const watchShortDescription = watch('short_description');

    const handleReset = () => {
        setValue('title', '');
        setValue('short_description', '');
        setValue('description', '');
        setValue('featured_image', '');
        setValue('post_image', '');
        setFeaturedImageBase64('');
        setPostImageBase64('');
        setFeaturedImageName('');
        setPostImageName('');
        setFileError('');
    };

    useEffect(() => {
        if (addPublicSuccess === 'done') {
            navigate(APP_ROUTES.POSTS);
        }
    }, [addPublicSuccess]);

    const onSubmit = (data:any) => {
        setIsSubmitting(true);
        const payload = {
            id: isEditMode ? editData?.id : '', 
            candidate_id: decrptionUser?.candidate_id || '', 
            title: data.title, 
            short_description: data.short_description, 
            description: data.description, 
            featured_image: featuredImageBase64 || '', 
            post_image: postImageBase64 || '', 
            status: '1', 
        };

        if (!isEditMode) {
            dispatch(savePostRequest(payload)); 
        } else {
            dispatch(updatePostRequest(payload as any)); 
        }
        setTimeout(() => setIsSubmitting(false), 2000);
    };

    const readFile = (file:any, onLoadCallback:any) => {
        const reader = new FileReader();
        reader.onload = () => onLoadCallback(reader.result);
        reader.readAsDataURL(file);
    };

    const handleFileChange = (event:any, field:any) => {
        const file = event.target.files[0];
        setFileError('');
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setFileError("Please select a file smaller than 200kB.");
                return;
            }
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                setFileError("Invalid file type. Only JPEG and PNG are allowed.");
                return;
            }

            if (field === 'featured_image') {
                setFeaturedImageName(file.name);
            } else if (field === 'post_image') {
                setPostImageName(file.name);
            }

            readFile(file, (result:any) => {
                const base64String = result.split(",")[1]; 
                if (field === 'featured_image') {
                    setFeaturedImageBase64(base64String); 
                } else if (field === 'post_image') {
                    setPostImageBase64(base64String); 
                }
            });
        }
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Paper 
                elevation={3} 
                sx={{ 
                    borderRadius: '12px',
                    overflow: 'hidden',
                    backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    backdropFilter: 'blur(10px)',
                    transition: 'all 0.3s ease-in-out',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box 
                        sx={{ 
                            backgroundColor: theme.palette.primary.main,
                            padding: '16px 24px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography 
                            fontSize={24} 
                            fontWeight={600} 
                            color="white" 
                            display="flex" 
                            alignItems="center"
                            sx={{ textShadow: '0 1px 2px rgba(0,0,0,0.2)' }}
                        >
                            {isEditMode ? (
                                <EditIcon sx={{ marginRight: '12px', fontSize: 28 }} />
                            ) : (
                                <PostAddIcon sx={{ marginRight: '12px', fontSize: 28 }} />
                            )}
                            {isEditMode ? t('EDIT_POST') : t('ADD_POST')}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Chip 
                                icon={<InfoOutlinedIcon />} 
                                label={isEditMode ? "Editing Mode" : "New Post"} 
                                color={isEditMode ? "secondary" : "primary"}
                                variant="outlined"
                                sx={{ 
                                    marginRight: '16px', 
                                    color: 'white', 
                                    borderColor: 'white',
                                    '& .MuiChip-icon': { color: 'white' }
                                }} 
                            />
                            <CustomButton
                                type="button"
                                text={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ArrowBackIcon style={{ marginRight: '5px' }} />
                                        Go Back
                                    </div>
                                }
                                color="secondary"
                                className="p-8"
                                onClick={() => navigate(APP_ROUTES.POSTS)}
                            />
                        </Box>
                    </Box>

                    <Grid container spacing={3} sx={{ padding: '24px' }}>
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    padding: '20px', 
                                    borderRadius: '8px',
                                    borderLeft: `4px solid ${primaryColor}`,
                                }}
                            >
                                <Typography variant="h6" gutterBottom fontWeight={500} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TitleIcon sx={{ marginRight: '8px', color: primaryColor }} />
                                    Post Details
                                </Typography>
                                <Divider sx={{ marginBottom: '16px' }} />
                                
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <LabelWithIcon
                                            Icon={TitleIcon}
                                            label={t('TITLE')}
                                            justifyContent="flex-start"
                                            className="mb-6"
                                            labelProps={{ color: 'black', fontWeight: 500 }}
                                            iconBackgroundColor={alpha(primaryColor, 0.1)}
                                            iconProps={{ color: primaryColor }}
                                            tooltip="Enter a clear and concise title for your post"
                                        />
                                        <FormInput
                                            name="title"
                                            type="text"
                                            placeholder={`${t('TITLE')}...`}
                                            control={control}
                                            className="input-from"
                                            rules={{
                                                required: { value: true, message: t('PLEASE_ENTER_TITLE') },
                                            }}
                                            inputProps={{ 
                                                ...register('title')
                                            }}
                                            startAdornment={<TitleIcon fontSize="small" color="action" />}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LabelWithIcon
                                            Icon={ShortTextIcon}
                                            label={t('SHORT DESCRIPTION')}
                                            justifyContent="flex-start"
                                            className="mb-6"
                                            labelProps={{ color: 'black', fontWeight: 500 }} 
                                            iconBackgroundColor={alpha(primaryColor, 0.1)}
                                            iconProps={{ color: primaryColor }}
                                            tooltip="A brief summary of your post (appears in post listings)"
                                        />
                                        <FormInput
                                            name="short_description"
                                            type="text"
                                            placeholder={`${t('SHORT DESCRIPTION')}...`}
                                            control={control}
                                            className="input-from"
                                            multipleLine
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('PLEASE_ENTER_SHORT_DESCRIPTION'),
                                                },
                                            }}
                                            inputProps={{ 
                                                ...register('short_description')
                                            }}
                                            startAdornment={<ShortTextIcon fontSize="small" color="action" />}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

  
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    padding: '20px', 
                                    borderRadius: '8px',
                                    borderLeft: `4px solid ${warningColor}`,
                                }}
                            >
                                <Typography variant="h6" gutterBottom fontWeight={500} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <DescriptionIcon sx={{ marginRight: '8px', color: warningColor }} />
                                    Full Content
                                </Typography>
                                <Divider sx={{ marginBottom: '16px' }} />
                                
                                <LabelWithIcon
                                    Icon={DescriptionIcon}
                                    label={t('DESCRIPTION')}
                                    justifyContent="flex-start"
                                    className="mb-6"
                                    labelProps={{ color: 'black', fontWeight: 500 }} 
                                    iconBackgroundColor={alpha(warningColor, 0.1)}
                                    iconProps={{ color: warningColor }}
                                    tooltip="The full content of your post"
                                />
                                <FormInput
                                    name="description"
                                    type="text"
                                    placeholder={`${t('DESCRIPTION')}...`}
                                    control={control}
                                    multipleLine
                                    className="input-from"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('PLEASE_ENTER_DESCRIPTION'),
                                        },
                                    }}
                                    inputProps={{ 
                                        ...register('description')
                                    }}
                                    startAdornment={<DescriptionIcon fontSize="small" color="action" />}
                                    errors={errors}
                                />
                            </Paper>
                        </Grid>

                        
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    padding: '20px', 
                                    borderRadius: '8px',
                                    borderLeft: `4px solid ${successColor}`,
                                }}
                            >
                                <Typography variant="h6" gutterBottom fontWeight={500} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PhotoLibraryIcon sx={{ marginRight: '8px', color: successColor }} />
                                    Media
                                </Typography>
                                <Divider sx={{ marginBottom: '16px' }} />
                                
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                padding: '16px',
                                                backgroundColor: alpha(successColor, 0.05),
                                                borderRadius: '8px',
                                                border: `1px dashed ${alpha(successColor, 0.3)}`,
                                            }}
                                        >
                                            <LabelWithIcon
                                                Icon={ImageIcon}
                                                label={t('FEATURED IMAGE')}
                                                justifyContent="flex-start"
                                                className="mb-6"
                                                labelProps={{ color: 'black', fontWeight: 500 }} 
                                                iconBackgroundColor={alpha(successColor, 0.1)}
                                                iconProps={{ color: successColor }}
                                                tooltip="This image appears as the main header image"
                                            />
                                            <Box 
                                                sx={{ 
                                                    display: 'flex', 
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                    backgroundColor: alpha(theme.palette.common.white, 0.8),
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    border: `1px solid ${theme.palette.divider}`,
                                                    '&:hover': {
                                                        backgroundColor: alpha(theme.palette.common.white, 0.95),
                                                    }
                                                }}
                                                onClick={() => {
                                                    const input = document.getElementById('featured_image') as HTMLInputElement;
                                                    if (input) input.click();
                                                }}
                                            >
                                                <CloudUploadIcon sx={{ fontSize: 40, color: successColor, marginBottom: '8px' }} />
                                                <Typography variant="body2" color="textSecondary" align="center">
                                                    {featuredImageName ? featuredImageName : 'Click to upload featured image'}
                                                    <br />
                                                    <small>(JPEG or PNG, max 200KB)</small>
                                                </Typography>
                                               <input
                                                    id="featured_image"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    {...register('featured_image', {
                                                        required: !featuredImageBase64 && !editData?.featured_image,
                                                        onChange: (e) => handleFileChange(e, 'featured_image')
                                                    })}
                                                />
                                            </Box>
                                            
                                            {featuredImageBase64 && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                                    <CheckCircleOutlineIcon fontSize="small" sx={{ color: successColor, marginRight: '4px' }} />
                                                    <Typography color="success.main" fontSize={12}>
                                                        Featured image uploaded successfully
                                                    </Typography>
                                                </Box>
                                            )}
                                            
                                            {errors?.featured_image && (
                                                <Typography color="error" fontSize={12} sx={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
                                                    <ErrorOutlineIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                                    {t('PLEASE_ENTER_FEATURED_IMAGE_URL')}
                                                </Typography>
                                            )}
                                        </Paper>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                padding: '16px',
                                                backgroundColor: alpha(successColor, 0.05),
                                                borderRadius: '8px',
                                                border: `1px dashed ${alpha(successColor, 0.3)}`,
                                            }}
                                        >
                                            <LabelWithIcon
                                                Icon={PhotoLibraryIcon}
                                                label={t('POST IMAGE')}
                                                justifyContent="flex-start"
                                                className="mb-6"
                                                labelProps={{ color: 'black', fontWeight: 500 }} 
                                                iconBackgroundColor={alpha(successColor, 0.1)}
                                                iconProps={{ color: successColor }}
                                                tooltip="This image appears within the post content"
                                            />
                                            <Box 
                                                sx={{ 
                                                    display: 'flex', 
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                    backgroundColor: alpha(theme.palette.common.white, 0.8),
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    border: `1px solid ${theme.palette.divider}`,
                                                    '&:hover': {
                                                        backgroundColor: alpha(theme.palette.common.white, 0.95),
                                                    }
                                                }}
                                                onClick={() => {
                                                    const input = document.getElementById('post_image') as HTMLInputElement;
                                                    if (input) input.click();
                                                }}
                                            >
                                                <CloudUploadIcon sx={{ fontSize: 40, color: successColor, marginBottom: '8px' }} />
                                                <Typography variant="body2" color="textSecondary" align="center">
                                                    {postImageName ? postImageName : 'Click to upload post image'}
                                                    <br />
                                                    <small>(JPEG or PNG, max 200KB)</small>
                                                </Typography>
                                             <input
                                                        id="post_image"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        {...register('post_image', {
                                                            required: !postImageBase64 && !editData?.post_image,
                                                            onChange: (e) => handleFileChange(e, 'post_image')
                                                        })}
                                                    />
                                            </Box>
                                            
                                            {postImageBase64 && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                                    <CheckCircleOutlineIcon fontSize="small" sx={{ color: successColor, marginRight: '4px' }} />
                                                    <Typography color="success.main" fontSize={12}>
                                                        Post image uploaded successfully
                                                    </Typography>
                                                </Box>
                                            )}
                                            
                                            {errors?.post_image && (
                                                <Typography color="error" fontSize={12} sx={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
                                                    <ErrorOutlineIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                                    {t('PLEASE_ENTER_POST_IMAGE_URL')}
                                                </Typography>
                                            )}
                                        </Paper>
                                    </Grid>
                                </Grid>

                                {fileError && (
                                    <Box sx={{ marginTop: '16px', padding: '12px', backgroundColor: alpha(errorColor, 0.1), borderRadius: '4px' }}>
                                        <Typography color="error" display="flex" alignItems="center">
                                            <WarningIcon fontSize="small" sx={{ marginRight: '8px' }} />
                                            {fileError}
                                        </Typography>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>


                        {(watchTitle || watchShortDescription || featuredImageBase64) && (
                            <Grid item xs={12}>
                                <Paper 
                                    elevation={1} 
                                    sx={{ 
                                        padding: '20px', 
                                        borderRadius: '8px',
                                        borderLeft: `4px solid ${theme.palette.info.main}`,
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom fontWeight={500} sx={{ display: 'flex', alignItems: 'center' }}>
                                      <PreviewIcon props={{ sx: { marginRight: '8px', color: theme.palette.info.main } }} />
                                        Post Preview
                                    </Typography>
                                    <Divider sx={{ marginBottom: '16px' }} />
                                    
                                    <Box sx={{ backgroundColor: alpha(theme.palette.info.main, 0.05), borderRadius: '8px', padding: '12px' }}>
                                        <Grid container spacing={2}>
                                            {featuredImageBase64 && (
                                                <Grid item xs={12} sm={4}>
                                                    <Box
                                                        sx={{
                                                            backgroundColor: theme.palette.common.white,
                                                            borderRadius: '4px',
                                                            border: `1px solid ${theme.palette.divider}`,
                                                            height: '150px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <ImageIcon sx={{ fontSize: 60, color: alpha(theme.palette.info.main, 0.3) }} />
                                                    </Box>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={featuredImageBase64 ? 8 : 12}>
                                                <Typography variant="h6" gutterBottom>
                                                    {watchTitle || 'Title will appear here'}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {watchShortDescription || 'Short description will appear here...'}
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                                                    <Chip label="Preview Only" size="small" color="info" sx={{ marginRight: '8px' }} />
                                                    <Typography variant="caption" color="textSecondary">
                                                        This is how your post might appear in listings
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        )}

                    
                        <Grid item xs={12}>
                            <Divider sx={{ marginBottom: '16px' }} />
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <CustomButton
                                        type="button"
                                        text={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <RestartAltIcon style={{ marginRight: '8px' }} />
                                                {t('RESET')}
                                            </div>
                                        }
                                        color="secondary"
                                        className="p-12"
                                        onClick={handleReset}
                                        sx={{ minWidth: '120px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomButton
                                        type="submit"
                                        text={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {isSubmitting ? (
                                                    <AutorenewIcon style={{ marginRight: '8px', animation: 'spin 1s linear infinite' }} />
                                                ) : (
                                                    <SaveIcon style={{ marginRight: '8px' }} />
                                                )}
                                                {isSubmitting ? 'Saving...' : t('SUBMIT')}
                                            </div>
                                        }
                                        color="warning"
                                        className="p-12"
                                        disabled={
                                            isSubmitting ||
                                            addPublicSuccess === 'loading' ||
                                            addPrivateSuccess === 'loading'
                                        }
                                        sx={{ minWidth: '120px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            
          
            <style>
                {`
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                `}
            </style>
        </Box>
    );
};

// Additional icon for preview section
const PreviewIcon = ({ props }: { props: any }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="currentColor"/>
        </svg>
    );
};