import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from '../../common';
import { INITIAL_STATE_TYPE } from "../../models/CommonModels";
import { TalukasModel } from "../../models/TalukaModels";
import { RootState } from "../store";

interface talukDetailsTypes extends INITIAL_STATE_TYPE {
    data: null | TalukasModel;
}

interface TalukaListTypes extends INITIAL_STATE_TYPE {
    data: TalukasModel[];
}

interface InitialStateTypes {
    talukaList: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    saveTaluka: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    updateTaluka: {
        status: LoadingState;
        data: any;
        error: any;
        message: any;
    };
    deleteTaluka: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | null;
    };
}

const initialState: InitialStateTypes = {
    talukaList: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    saveTaluka: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    updateTaluka: {
        status: 'idle',
        data: undefined,
        error: undefined,
        message: undefined,
    },
    deleteTaluka: {
        loading: false,
        success: false,
        error: false,
        message: null,
    },
};

const talukaSlice = createSlice({
    name: 'taluka',
    initialState,
    reducers: {
        talukaListInProgress: (state) => {
            state.talukaList.status = 'loading';
            state.talukaList.data = undefined;
            state.talukaList.error = undefined;
        },
        talukaListInSuccess: (state, { payload }) => {
            state.talukaList.status = 'done';
            state.talukaList.data = payload.data;
            state.talukaList.error = undefined;
            state.talukaList.message = payload.message;
        },
        talukaListInError: (state, { payload }) => {
            state.talukaList.status = 'error';
            state.talukaList.data = undefined;
            state.talukaList.error = payload;
            state.talukaList.message = payload.message;
        },
        saveTalukaRequest: (state) => {
            state.saveTaluka.status = 'loading';
            state.saveTaluka.data = undefined;
            state.saveTaluka.error = undefined;
        },
        saveTalukaSuccess: (state, { payload }) => {
            state.saveTaluka.status = 'done';
            state.saveTaluka.data = payload.data;
            state.saveTaluka.error = undefined;
            state.saveTaluka.message = payload.message;
        },
        saveTalukaError: (state, { payload }) => {
            state.saveTaluka.status = 'error';
            state.saveTaluka.data = undefined;
            state.saveTaluka.error = payload;
            state.saveTaluka.message = payload.message;
        },
        updateTalukaRequest: (state) => {
            state.updateTaluka.status = 'loading';
            state.updateTaluka.data = undefined;
            state.updateTaluka.error = undefined;
        },
        updateTalukaSuccess: (state, { payload }) => {
            state.updateTaluka.status = 'done';
            state.updateTaluka.data = payload.data;
            state.updateTaluka.error = undefined;
            state.updateTaluka.message = payload.message;
        },
        updateTalukaError: (state, { payload }) => {
            state.updateTaluka.status = 'error';
            state.updateTaluka.data = undefined;
            state.updateTaluka.error = payload;
            state.updateTaluka.message = payload.message;
        },
        deleteTalukaInProgress: (state, {}: PayloadAction<{ data: any; message: string }>) => {
            state.deleteTaluka.loading = true;
            state.deleteTaluka.success = false;
            state.deleteTaluka.error = false;
            state.deleteTaluka.message = null;
        },
        deleteTalukaInSuccess: (state, { payload }: PayloadAction<{ data: any; message: string }>) => {
            state.deleteTaluka.loading = false;
            state.deleteTaluka.success = true;
            state.deleteTaluka.message = payload.message;
        },
        deleteTalukaInError: (state, { payload }: PayloadAction<{ message: string }>) => {
            state.deleteTaluka.loading = false;
            state.deleteTaluka.error = true;
            state.deleteTaluka.message = payload.message;
        },
        talukaDefaultData: (state) => {
            state.saveTaluka.status = 'loading';
            state.saveTaluka.data = undefined;
            state.saveTaluka.error = undefined;
        },
    },
});

export const {
    talukaListInProgress,
    talukaListInSuccess,
    talukaListInError,
    saveTalukaRequest,
    saveTalukaSuccess,
    saveTalukaError,
    talukaDefaultData,
    updateTalukaRequest,
    updateTalukaSuccess,
    updateTalukaError,
    deleteTalukaInProgress,
    deleteTalukaInSuccess,
    deleteTalukaInError,
} = talukaSlice.actions;

export const selectTalukaListLoading = (state: RootState) =>
    state.taluka.talukaList.status === 'loading'

export const selectTalukaListInfo = (state: RootState) =>
    state.taluka.talukaList.data;

export const selectTalukaListError = (state: RootState) =>
    state.taluka.talukaList.error;

export const saveTalukaSuccessData = (state: RootState) =>
    state.taluka.saveTaluka.status;

export default talukaSlice;