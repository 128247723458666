import { Instance } from '../../common/axiosInstance';

const token = localStorage.getItem('accessToken');

export const getTalukaListEffect = (payload: any) => {
  return Instance.request({
    url: `/talukas/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&parliment_id=${payload.parliment_id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const addTalukaEffect = (payload: any) => {
  return Instance.request({
    url: '/talukas/add',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: payload,
  });
};

export const updateTalukaEffect = (payload: any) => {
  return Instance.request({
    url: '/talukas/update',
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: payload,
  });
};

export const viewTalukaEffect = (payload: any) => {
  return Instance.request({
    url: `/talukas/details?taluk_id=${payload.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const deleteTalukaEffect = (payload: any) => {
  return Instance.request({
    url: `/talukas/delete/${payload.id}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};