import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import CustomButton from '../../../../common/ui/CustomButton';
import DeleteModel from '../../../../common/ui/Modal/DeleteModel';
import TableComponent from '../../../../common/ui/TableComponent';
import {
	postDefaultData,
	postListInProgress,
	selectPostListInfo,
	selectPostListLoading,
} from '../../../../store/Post/_post.slice';
import '../candidate.scss';
import PostHeader from './PostHeader';

const PostList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [deleteData, setDeleteData] = useState(false);
	const publicList = useSelector(selectPostListInfo);
	const publicListLoading = useSelector(selectPostListLoading);
	const [openViewDialog, setOpenViewDialog] = useState(false);
	const [selectedPost, setSelectedPost] = useState<any>(null);

	const {
		reset,
	} = useForm();

	const userInfo = localStorage.getItem('userInfo');
	const decryptionUser  = JSON.parse(cryptoEncryptionDecryption.Decrypt(userInfo || '{}'));

	const columns: GridColDef[] = [
		{
			headerName: t('TITLE'),
			field: 'title',
			disableColumnMenu: true,
			sortable: false,
			flex: 0.5,
		},
		{
			headerName: t('SHORT DESCRIPTION'),
			field: 'short_description',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('DESCRIPTION'),
			field: 'description',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('FEATURED IMAGE'),
			field: 'featured_image_url',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
			renderCell: ({ row }) => (
				<img src={row.featured_image_url} alt="Featured" width={50} height={50} />
			),
		},
		{
			headerName: t('POST IMAGE'),
			field: 'image_url',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
			renderCell: ({ row }) => (
				<img src={row.image_url} alt="Post" width={50} height={50} />
			),
		},
		{
			headerName: t('STATUS'),
			field: 'status',
			disableColumnMenu: true,
			sortable: false,
			flex: 0.5,
			renderCell: ({ row }) => (
				<span style={{
				backgroundColor: row.status === 1 ? '#C6F4D6' : '#F7D2C4',
				color: row.status === 1 ? '#3E8E41' : '#FF9900',
				padding: '2px 5px',
				borderRadius: '5px',
				}}>
				{row.status === 1 ? t('Active') : t('Inactive')}
				</span>
			),
		},
		{
			headerName: 'Action',
			field: 'Action',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
			renderCell: ({ row }) => (
				<>
					<CustomButton
						text={'Edit'}
						className="mx-3 color-gray"
						onClick={() => handleEdit(row)}
					/>
					<CustomButton
						text={'View'}
						className="mx-3"
						color={'warning'}
						onClick={() => handleView(row)}
					/>
					<CustomButton
						text={'Delete'}
						className="mx-3"
						color={'warning'}
						onClick={() => handleDelete(row.id)}
					/>
				</>
			),
		},
	];

	const handleEdit = (row: any) => {
		navigate(APP_ROUTES.POSTEDIT, {
			state: { data: { data: row, type: 'edit' } },
		});
	};

	const handleView = (row: any) => {
		setSelectedPost(row);
		setOpenViewDialog(true);
	};

	const handleDelete = (id: number) => {
		setOpenDeleteModal(true);
		setDeleteId(id);
	};

	useEffect(() => {
		if (deleteData) {
			// handleDelete logic here
		}
	}, [deleteData]);

	useEffect(() => {
		const payloads = {
			canditate_id: decryptionUser ?.candidate_id || '',
			people_id: decryptionUser ?.people_id || '',
			pagination_required: true,
			pageNo:1
		};
		dispatch(postListInProgress(payloads as any));
		dispatch(postDefaultData());
	}, [dispatch]);

	useEffect(() => {
		setLoading(publicListLoading);
	}, [publicListLoading]);

	const handlePagination = (data: any) => {
		const payloads = {
			canditate_id: decryptionUser ?.candidate_id || '',
			people_id: decryptionUser ?.people_id || '',
			pageNo: data?.page + 1,
			pagination_required: true,
		};
		dispatch(postListInProgress(payloads as any));
	};

	return (
		<Grid container my={5}>
			<Grid item xs={12}>
			<PostHeader view={selectedPost} />
			</Grid>
		
			{loading ? (
				<Grid item xs={12} my={5} display={'flex'} justifyContent={'center'}>
					<CircularIndeterminate />
				</Grid>
			) : (
				<Grid item xs={12} my={5}>
			<Paper sx={{ padding: 2  }}>
					<TableComponent
						rows={publicList?.data || []}
						columns={columns}
						type="public"
						totalRecords={publicList?.totalRecords || 0}
						handlePagination={handlePagination}
						paginationModel={{
							page: Number(publicList?.currentPage) - 1 || 0,
							pageSize: Number(publicList?.pagesize) || 5,
						}}
						handleAdd={() => navigate(APP_ROUTES.POSTADD)}
					/>
					</Paper>
				</Grid>
			)}

			<DeleteModel
				setOpen={setOpenDeleteModal}
				open={openDeleteModal}
				handleDelete={''} // Add your delete handler here
				setDeleteData={setDeleteData}
			/>
			
			<Dialog
			open={openViewDialog}
			onClose={() => setOpenViewDialog(false)}
			maxWidth="sm"
			fullWidth
			>
			<DialogTitle>{t('Post Details')}</DialogTitle>
			<DialogContent>
				{selectedPost && (
				<Grid container spacing={2}>
					{['title', 'short_description', 'description', 'featured_image_url', 'image_url', 'status'].map((key) => (
					<Grid item xs={12} key={key}>
						<Typography variant="h6" sx={{ fontWeight: 'bold' }}>{t(key.replace(/_/g, ' ').toUpperCase())}:</Typography>
						{(key === 'featured_image_url' || key === 'image_url') ? (
						<img src={selectedPost[key]} alt={key === 'featured_image_url' ? 'Featured' : 'Post'} width={100} height={100} />
						) : (
						<Typography variant="body1">
							{key === 'status' ? (
							selectedPost[key] === 1 ? (
								<span style={{ backgroundColor: 'green', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
								{t('Active')}
								</span>
							) : (
								<span style={{ backgroundColor: 'red', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
								{t('Inactive')}
								</span>
							)
							) : (
							selectedPost[key] || 'N/A'
							)}
						</Typography>
						)}
					</Grid>
					))}
				</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<CustomButton text={t('Close')} onClick={() => setOpenViewDialog(false)} />
			</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default PostList;