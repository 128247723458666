import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ForwardIcon from "@mui/icons-material/Forward";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
	Box,
	Card,
	CardContent,
	Chip,
	ChipProps,
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cryptoEncryptionDecryption from '../../common/crypto';
import { PetetionListRequest, selectPetetionListInfo } from "../../store/petetions/_petetion.slice";

const PetitionDashboard = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [petitionTypeCount, setPetitionTypeCount] = useState();
  const userInfo = localStorage.getItem('userInfo');
  const { t } = useTranslation();
  const petetionList = useSelector(selectPetetionListInfo);
  const dispatch = useDispatch();
  const decrptionUser = JSON.parse(
    cryptoEncryptionDecryption.Decrypt(userInfo)
  );

  useEffect(() => {
    const payloads = {
      canditate_id: decrptionUser?.candidate_id
        ? decrptionUser?.candidate_id
        : '',
      people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
      pageNo: 1,
      pagination: true,
      petition_type: '',
      approve_status:'1'
    };

    dispatch(PetetionListRequest(payloads as any));
  }, []);
  
  console.log(petetionList,"petetionList");

  const cards = [
    {
      title: "Total Petitions",
      value: "650",
      change: "22 this week",
      icon: <AssignmentIcon fontSize="large" />, 
      color: "#525252",
      borderColor: "#ed6c03"
    },
    {
      title: "Active Petitions",
      value: "420",
      change: "15 this week",
      icon: <HourglassEmptyIcon fontSize="large" />, 
      color: "#ed6c03",
      borderColor: "#525252"
    },
    {
      title: "Petitions Forwarded",
      value: "1.2M",
      change: "125K this week",
      icon: <ForwardIcon fontSize="large" />, 
      color: "#525252",
      borderColor: "#ed6c03"
    },
    {
      title: "Petitions Resolved",
      value: "750K",
      change: "20K this week",
      icon: <CheckCircleIcon fontSize="large" />, 
      color: "#ed6c03",
      borderColor: "#525252"
    }
  ];

  const trendData = [
    { month: 'Jan', Environmental: 65, Social: 28, Health: 45, Education: 35 },
    { month: 'Feb', Environmental: 75, Social: 42, Health: 55, Education: 40 },
    { month: 'Mar', Environmental: 85, Social: 48, Health: 65, Education: 55 },
    { month: 'Apr', Environmental: 95, Social: 62, Health: 75, Education: 65 },
    { month: 'May', Environmental: 105, Social: 75, Health: 85, Education: 75 },
    { month: 'Jun', Environmental: 115, Social: 82, Health: 95, Education: 85 },
    { month: 'Jul', Environmental: 125, Social: 90, Health: 105, Education: 95 },
    { month: 'Aug', Environmental: 135, Social: 98, Health: 115, Education: 105 },
    { month: 'Sep', Environmental: 145, Social: 106, Health: 125, Education: 115 },
    { month: 'Oct', Environmental: 155, Social: 114, Health: 135, Education: 125 },
    { month: 'Nov', Environmental: 165, Social: 122, Health: 145, Education: 135 },
    { month: 'Dec', Environmental: 175, Social: 130, Health: 155, Education: 145 }
  ];
    
  const columns = [
    {
      headerName: t('FULL_NAME'),
      field: 'full_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('EMAIL'),
      field: 'email',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('MOBILE_NUMBER'),
      field: 'mobile',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ADDRESS'),
      field: 'address',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('CANDIDATE_NAME'),
      field: 'candidate_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'subject',
      headerName: t('SUBJECT'),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: t('STATUS'),
      field: 'status_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ value }: any) => {
        let chipColor: ChipProps['color'] = 'default';
        let chipBg = '';
        let chipTextColor = '';

        switch (value) {
          case 'pending':
            chipBg = '#f5f5f5';
            chipTextColor = '#525252';
            break;
          case 'inPrgress':
            chipBg = '#525252';
            chipTextColor = '#ffffff';
            break;
          case 'forwarded':
            chipBg = '#ed6c03';
            chipTextColor = '#ffffff';
            break;
          case 'resolved':
            chipBg = '#2e7d32';
            chipTextColor = '#ffffff';
            break;
          case 'reject':
            chipBg = '#d32f2f';
            chipTextColor = '#ffffff';
            break;
          default:
            chipBg = '#f5f5f5';
            chipTextColor = '#525252';
            break;
        }

        return (
          <Chip 
            label={value} 
            sx={{
              backgroundColor: chipBg,
              color: chipTextColor,
              fontWeight: 'bold',
              border: '1px solid #525252'
            }} 
          />
        );
      },
    },
  ];

  return (
    <Box p={3} bgcolor="#f8f8f8">
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 3, 
        pb: 1, 
        borderBottom: '3px solid #ed6c03'
      }}>
        <Typography 
          variant="h4" 
          fontWeight="bold" 
          color="#525252"
          sx={{ 
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: 0,
              width: '60px',
              height: '3px',
              backgroundColor: '#ed6c03'
            }
          }}
        >
          Petition Dashboard
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={6} xl={3} key={index}>
            <Card 
              sx={{ 
                backgroundColor: card.color, 
                color: "#fff", 
                borderLeft: `5px solid ${card.borderColor}`,
                borderRadius: '4px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
              }}
            >
              <CardContent sx={{ textAlign: 'center', padding: 3 }}>
                <Box 
                  sx={{ 
                    backgroundColor: 'rgba(255,255,255,0.2)', 
                    borderRadius: '50%', 
                    width: 60, 
                    height: 60, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    margin: '0 auto 12px auto'
                  }}
                >
                  {card.icon}
                </Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {card.title}
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                  {card.value}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                  <ArrowUpwardIcon fontSize="small" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {card.change}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ marginTop: 5, marginBottom: 2 }}>
        <Typography 
          variant="h6" 
          gutterBottom 
          fontWeight="bold" 
          color="#525252"
          sx={{ 
            textTransform: 'uppercase',
            position: 'relative',
            display: 'inline-block',
            paddingBottom: 1,
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '2px',
              backgroundColor: '#ed6c03'
            }
          }}
        >
          Active Petitions
        </Typography>
      </Box>
      
      <Paper elevation={3} sx={{ borderRadius: '6px', overflow: 'hidden' }}>
        <DataGrid
          rows={petetionList?.data ? petetionList?.data : []}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#525252",
              color: "white",
              fontWeight: 'bold',
              fontSize: '14px'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'uppercase',
              letterSpacing: '0.5px'
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#f8f8f8',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: '#ffffff',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(237, 108, 3, 0.08)'
            },
            '& .MuiTablePagination-root': {
              display: 'none'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #e0e0e0'
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: '2px solid #525252'
            }
          }}
        />
      </Paper>
    </Box>
  );
};

export default PetitionDashboard;