import { axiosInstance, getInstance } from '../../common/axiosInstance';
import { PostRoutes } from '../../common/routes';

const axios = axiosInstance;
const getaxios = getInstance;

const {
  postLists,
	savePost,
  updatePost,
  getPost,
  deletePost
} = PostRoutes;

const addPosts = (data: any) =>
  axios.request({
    url: `${savePost.url}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
    data: data,
  });

const updatePosts = (data: any) => {
  console.log(data.id);
  axios.request({
    url: `${updatePost.url}/${data?.id}`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
    data: data,
  }).then((res) => console.log(res)).catch((err) => console.error(err));
}

const postDetails = (data: any) =>
  getaxios.request({
    url: `${getPost.url}?post_id=${data?.post_id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
  });
///posts/list?pagination_required=false&candidate_id=0856687a-79de-4fe9-80c6-85fc5aa2dd0b
const postList = (data: any) =>
  getaxios.request({
    url: `${postLists.url}?pagination_required=true&&pageNo=${data?.pageNo}&candidate_id=${data?.canditate_id ? data?.canditate_id : ''}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
  });



const deletePostReq = (data: any) =>
  axios.request({
    url: `${deletePost.url}/${data?.id}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    },
  });

export {
  addPosts, deletePostReq, postDetails,
  postList, updatePosts
};

