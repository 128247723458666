import { Grid, Typography, useTheme } from '@mui/material';
import { createRef, useRef, useState } from 'react';

import Cropper, { ReactCropperElement } from 'react-cropper';


import WebCamModal from './WebCamModal';

import 'cropperjs/dist/cropper.css';
import { convertToBase64, stripBase64Prefix } from '../../../src/common/utils';
import Separator from '../Separator';
import CommonModal from './CommonModal';
import CustomButton from './CustomButton';
import './Modal.scss';

const ImagePickerModal = ({
  open,
  setOpen,
  handleImage,
  removePrefix = true,
  enableCropping = false,
  disableWebcam = false,
}: any) => {
  const cropperRef = createRef<ReactCropperElement>();
  const [webCamModal, setWebCamModal] = useState<any>(false);
  const [cropperModal, setCropperModal] = useState<any>(false);
  const [image, setImage] = useState<any>();
  const [imageEvent, setImageEvent] = useState<any>();
  const theme = useTheme();
  const fileInputRef = useRef<any>(null);

  const onCapture = (imageBase64: any) => {
    if (imageBase64) {
      setWebCamModal(false);
      setOpen(false);
      if (enableCropping) {
        setImage(imageBase64);
        setCropperModal(true);
      } else {
        if (removePrefix) {
          imageBase64 = stripBase64Prefix(imageBase64);
        }
        handleImage(imageBase64);
      }
    }
  };

  const handleChooseFileClick = () => {
    try {
      if (fileInputRef?.current) {
        fileInputRef.current.type = 'text';
        fileInputRef.current.type = 'file';
        fileInputRef?.current?.click();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleImagePicked = async (e: any) => {
    try {
      if (!String(e?.target?.files[0].type).includes('image')) {
        // eslint-disable-next-line no-throw-literal
        throw 'Invalid file type';
      }
      let base64Image: any = await convertToBase64(e?.target?.files[0]);
      setOpen(false);
      if (enableCropping) {
        setImage(base64Image);
        setImageEvent(e);
        setCropperModal(true);
      } else {
        if (removePrefix) {
          base64Image = stripBase64Prefix(base64Image);
        }
        handleImage(base64Image, e);
      }
    } catch (error) {}
  };

  const handleCrop = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      let base64Image: any = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      if (removePrefix) {
        base64Image = stripBase64Prefix(base64Image);
      }
      handleImage(base64Image, imageEvent);
      setImage(false);
      setImageEvent(false);
      setCropperModal(false);
    }
  };
  
  const cancelCrop = () => {
    setCropperModal(false);
  };
  
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <WebCamModal
        open={webCamModal}
        setOpen={setWebCamModal}
        onCapture={onCapture}
      />
      <input
        ref={fileInputRef}
        className='display-none'
        type='file'
        accept='image/x-png,image/jpeg'
        onChange={handleImagePicked}
      />
      <CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName='py-20'
        boxProps={{ width: 320 }}
      >
        <Grid container justifyContent='center'>
          <Grid justifyContent='center'>
            <Typography color={theme.palette.info.main} fontWeight={600}>
              SELECT PHOTO
            </Typography>
          </Grid>
          <Grid justifyContent='center' className='mx-5'>
            <Typography
              color={theme.palette.common.black}
              fontWeight={600}
              fontSize={10}
            >
              JPEG SIZE SHOULD NOT EXCEED 5MB
            </Typography>
          </Grid>
        </Grid>
        <Separator color={theme.palette.info.main} className='my-10' />
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          flexWrap='nowrap'
        >
          <Grid xs={10} display={'flex'} justifyContent={'space-between'}>
            <CustomButton
              text={'CHOOSE_FILE'}
              variant='text'
              color='primary'
              showIcon={false}
              onClick={handleChooseFileClick}
              type='submit'
              fontWeight={600}
              fontSize={14}
            />
            <CustomButton
              text={'cancel'}
              variant='text'
              color='primary'
              showIcon={false}
              onClick={handleCancel}
              fontWeight={600}
              fontSize={14}
            />
          </Grid>
          {!disableWebcam && (
            <>
              <Grid>
                <div
                  className='mx-10'
                  style={{
                    width: 1,
                    height: 30,
                    borderRight: `1px solid ${theme.palette.info.main}`,
                  }}
                />
              </Grid>
              <Grid>
                <CustomButton
                  text={'OPEN_WEBCAM'}
                  variant='text'
                  color='primary'
                  showIcon={false}
                  onClick={() => setWebCamModal(true)}
                  type='submit'
                  fontWeight={600}
                  fontSize={14}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
      <CommonModal
        onClose={() => setCropperModal(false)}
        open={cropperModal}
        modalClassName='py-20'
        boxProps={{ maxHeight: '500vh' } as any}
      >
        <Grid container direction='column'>
          <Grid item flex={1}>
            <Cropper
              // ref={cropperRef}
              // style={{ height: 400, width: '100%' }}
              // zoomTo={0.5}
              // initialAspectRatio={1}
              // aspectRatio={1}
              // preview=".img-preview"
              // src={image}
              // viewMode={1}
              // minCropBoxHeight={10}
              // minCropBoxWidth={10}
              // background
              // responsive={true}
              // autoCropArea={1}
              // checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              // guides={true}
              // // draggable
              // // dragMode="move"
              style={{ height: 400, width: '100%' }}
              initialAspectRatio={1}
              preview='.img-preview'
              src={image}
              ref={cropperRef}
              viewMode={1}
              guides
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              checkOrientation={false}
            />
          </Grid>
          <Grid item display='flex' justifyContent='center'>
            <CustomButton
              text={'CANCEL'}
              variant='text'
              color='primary'
              showIcon={false}
              onClick={() => cancelCrop()}
              type='submit'
              fontWeight={600}
              fontSize={14}
              className='mt-20'
            />
            <CustomButton
              text={'DONE'}
              variant='text'
              color='primary'
              showIcon={false}
              onClick={() => handleCrop()}
              type='submit'
              fontWeight={600}
              fontSize={14}
              className='mt-20'
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default ImagePickerModal;
