import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
    addDepartmentEffect,
    deleteDepartmentEffect,
    getDepartmentListEffect,
    updateDepartmentEffect
} from './_department.service';
import {
    deleteDepartmentInError,
    deleteDepartmentInProgress,
    deleteDepartmentInSuccess,

    departmentListInError,
    departmentListInProgress,
    departmentListInSuccess,
    saveDepartmentError,
    saveDepartmentRequest,
    saveDepartmentSuccess,
    updateDepartmentError,
    updateDepartmentRequest,
    updateDepartmentSuccess,
} from './_department.slice';


function* getDepartmentsListWatcher({ payload }: any) {
    try {
        const { data } = yield call(getDepartmentListEffect, payload);
        console.log('Fetched data:', data); // Log the fetched data
        yield put(departmentListInSuccess({ data, message: 'Success' }));
    } catch (error) {
        console.error('Error fetching department list:', error); // Log the error
        yield put(departmentListInError({ message: "error" }));
    }
}

// function* getDepartmentDetailsWatcher({ payload }: ReturnType<typeof departmentDetailsInProgress>) {
//     try {
//         yield delay(3000); 
//         const { data } = yield call(viewDepartmentEffect, payload);
//         yield put(departmentDetailsInSuccess({ data, message: 'Success' }));
//     } catch (error) {
//         yield put(departmentDetailsInError({ message:"error" }));
//     }
// }


function* saveDepartmentWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000); 
        const { data } = yield call(addDepartmentEffect, payload);
        yield put(saveDepartmentSuccess({ data , message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(saveDepartmentError({ message:"error" }));
    }
}


function* editDepartmentWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000);
        const { data } = yield call(updateDepartmentEffect, payload);
        yield put(updateDepartmentSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(updateDepartmentError({ message:"error"}));
    }
}

function* deleteDepartmentWatcher({ payload: { handleSuccess, ...payload } }: any) {
    try {
        yield delay(3000);
        const { data } = yield call(deleteDepartmentEffect, payload);
        yield put(deleteDepartmentInSuccess({ data, message: 'Success' }));
        if (handleSuccess) {
            handleSuccess();
        }
    } catch (error) {
        yield put(deleteDepartmentInError({ message:"error"}));
    }
}


// Root saga for departments
export default function* DepartmentsSaga() {
    yield takeLatest(departmentListInProgress.type, getDepartmentsListWatcher);
    // yield takeLatest(departmentDetailsInProgress.type, getDepartmentDetailsWatcher);
    yield takeLatest(saveDepartmentRequest.type, saveDepartmentWatcher);
    yield takeLatest(updateDepartmentRequest.type, editDepartmentWatcher);
    yield takeLatest(deleteDepartmentInProgress.type, deleteDepartmentWatcher);
}