import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import DomainIcon from '@mui/icons-material/Domain';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ForwardIcon from '@mui/icons-material/Forward';
import HistoryIcon from '@mui/icons-material/History';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SubjectIcon from '@mui/icons-material/Subject';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Grid,
	Paper,
	Stack,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import theme from '../../../../common/theme';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import CustomButton from '../../../../common/ui/CustomButton';
import {
	SubconstituentListRequest,
	UpdatePetetionRequest,
	subconstituentData,
	updatePetitionLoading,
} from '../../../../store/petetions/_petetion.slice';
import '../candidate.scss';
import ForwardModal from './ForwardModal';
import ViewImageandFile from './ViewImageandFile';
import ViewPetitionLogs from './ViewPetitionLogs';

const ViewPetition = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forwardModal, setForwardModal] = useState(false);
  const [logModal, setLogModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [petitionType, setPetitionType] = useState('');
  const constituentList = useSelector(subconstituentData);
  const updateLoading = useSelector(updatePetitionLoading);
  const viewPetitionData = location?.state?.data?.data;
  const userInfo = localStorage.getItem('userInfo');
  const decrptionUser = JSON.parse(
    cryptoEncryptionDecryption.Decrypt(userInfo)
  );
  const constituent_id = localStorage.getItem('constituent_id');

  const primaryColor = '#525252';
  const secondaryColor = '#ed6c03';
  const getStatusBadge = (status: number): JSX.Element | undefined => {
    let color: 'default' | 'info' | 'primary' | 'warning' | 'success' | 'error' = 'default';
    let label = 'Unknown';
    let icon: JSX.Element | null = null;

    switch (status) {
      case 1:
        color = 'info';
        label = 'New';
        icon = <HistoryIcon fontSize="small" />;
        break;
      case 2:
        color = 'primary';
        label = 'In Progress';
        icon = <HistoryIcon fontSize="small" />;
        break;
      case 3:
        color = 'warning';
        label = 'Forwarded';
        icon = <ForwardIcon fontSize="small" />;
        break;
      case 4:
        color = 'success';
        label = 'Resolved';
        icon = <DoneAllIcon fontSize="small" />;
        break;
      case 5:
        color = 'error';
        label = 'Rejected';
        icon = null;
        break;
      default:
        break;
    }

    return (
      <Chip 
        icon={icon !== null ? icon : <></>} 
        label={label} 
        color={color} 
        size="medium" 
        variant="outlined"
      />
    );
  };

  const handleNavigate = () => {
    navigate(APP_ROUTES.PETITION);
  };

  const onClickForward = () => {
    setForwardModal(true);
    setPetitionType('forward');
  };

  const onClickReject = () => {
    setForwardModal(true);
    setPetitionType('reject');
  };

  const onClickResolved = () => {
    setForwardModal(true);
    setPetitionType('resolved');
  };

  const onClickInProgress = () => {
    const payload = {
      id: viewPetitionData?.id,
      petetionType: 'inprogress',
      data: {},
      handleNavigate,
    };
    setPetitionType('inprogress');
    dispatch(UpdatePetetionRequest(payload as any));
  };

  useEffect(() => {
    dispatch(SubconstituentListRequest(constituent_id as any));
  }, [constituent_id, dispatch]);

  useEffect(() => {
    setLoading(updateLoading);
  }, [updateLoading]);

  const DetailField = ({ label, value, icon }: { label: string; value: string; icon: JSX.Element }) => (
    <Box sx={{ mb: 2 }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Avatar sx={{ width: 24, height: 24, bgcolor: primaryColor }}>
          {icon}
        </Avatar>
        <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
          {label}
        </Typography>
      </Stack>
      <Typography 
        variant="body1" 
        sx={{ 
          p: 2, 
          backgroundColor: 'background.paper', 
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          minHeight: label === t('DESCRIPTION') || label === t('ADDRESS') ? '100px' : 'auto',
          boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
        }}
      >
        {value || '-'}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ 
      bgcolor: '#f5f7fa', 
      p: 3, 
      borderRadius: 2, 
      minHeight: '100vh' 
    }}>
      <Paper 
        elevation={2} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: primaryColor,
            color: 'white'
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <DescriptionIcon />
            <Typography
              fontSize={20}
              fontWeight={600}
            >
              {t('VIEW_PETITION')}
            </Typography>
            {viewPetitionData?.status && (
              <Box sx={{ ml: 2 }}>
                {getStatusBadge(viewPetitionData.status)}
              </Box>
            )}
          </Stack>
          <CustomButton
            type="button"
            text={t('GO_BACK')}
            startIcon={SubdirectoryArrowRightIcon}
            color="primary"
            className="p-8 color-gray"
            onClick={() => navigate(APP_ROUTES.PETITION)}
            sx={{ bgcolor: 'white' }}
          />
        </Box>

        {loading ? (
          <Grid item xs={12} my={5} display="flex" justifyContent="center">
            <CircularIndeterminate />
          </Grid>
        ) : (
          <>
            <Grid
              container
              sx={{
                p: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                bgcolor: '#f9f9f9'
              }}
            >
              <Grid item xs={12} md={6} p={2}>
                <CustomButton
                  type="button"
                  text={t('VIEW_LOGS')}
                  startIcon={RemoveRedEyeIcon}
                  color="warning"
                  className="p-8"
                  width="auto"
                  height="50px"
                  onClick={() => setLogModal(true)}
                  sx={{ bgcolor: secondaryColor, color: 'white' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {Number(decrptionUser?.role_id) === 2 && (
                  <Grid
                    item
                    xs={12}
                    justifyContent="space-between"
                    display="flex"
                    p={2}
                  >
                    <CustomButton
                      type="button"
                      text={t('INPROGRESS')}
                      color="primary"
                      className="p-12 mx-10 color-gray"
                      onClick={onClickInProgress}
                      disabled={viewPetitionData?.status > 2}
                      sx={{ bgcolor: primaryColor, color: 'white' }}
                    />
                    <CustomButton
                      type="button"
                      text={t('FORWARD')}
                      color="warning"
                      className="p-12"
                      onClick={onClickForward}
                      disabled={viewPetitionData?.status >= 3}
                      sx={{ bgcolor: secondaryColor, color: 'white' }}
                    />
                    <CustomButton
                      type="button"
                      text={t('RESOLVED')}
                      color="primary"
                      className="p-12 mx-10 color-gray"
                      onClick={onClickResolved}
                      disabled={viewPetitionData?.status >= 4}
                      sx={{ bgcolor: primaryColor, color: 'white' }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {/* Personal Information - 6 columns */}
                <Grid item xs={12} md={6}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      height: '100%',
                      borderRadius: 2,
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: primaryColor }}>
                          <PersonIcon />
                        </Avatar>
                      }
                      title={
                        <Typography variant="h6" fontWeight={600}>
                          {t('PERSONAL_INFORMATION')}
                        </Typography>
                      }
                      sx={{ 
                        bgcolor: `${primaryColor}20`,
                        color: primaryColor,
                        borderBottom: `1px solid ${theme.palette.divider}`
                      }}
                    />
                    <CardContent sx={{ overflow: 'auto', maxHeight: '600px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('FULL_NAME')} 
                            value={viewPetitionData?.full_name}
                            icon={<PersonIcon fontSize="small" />} 
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('MOBILE_NUMBER')} 
                            value={viewPetitionData?.mobile}
                            icon={<PhoneIcon fontSize="small" />} 
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('SUBJECT')} 
                            value={viewPetitionData?.subject}
                            icon={<SubjectIcon fontSize="small" />} 
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('ASSEMBLY_CONSTITUENCY')} 
                            value={viewPetitionData?.Parliamentsubconstituencies_name}
                            icon={<DomainIcon fontSize="small" />} 
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('ADDRESS')} 
                            value={viewPetitionData?.address}
                            icon={<LocationOnIcon fontSize="small" />} 
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DetailField 
                            label={t('DESCRIPTION')} 
                            value={viewPetitionData?.description}
                            icon={<DescriptionIcon fontSize="small" />} 
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Attachments Section - 6 columns */}
                <Grid item xs={12} md={6}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      height: '100%',
                      borderRadius: 2,
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: secondaryColor }}>
                          <AttachFileIcon />
                        </Avatar>
                      }
                      title={
                        <Typography variant="h6" fontWeight={600}>
                          {t('ATTACHMENTS')}
                        </Typography>
                      }
                      sx={{ 
                        bgcolor: `${secondaryColor}20`, // 20% opacity
                        color: secondaryColor,
                        borderBottom: `1px solid ${theme.palette.divider}`
                      }}
                    />
                    <CardContent sx={{ overflow: 'auto', maxHeight: '600px' }}>
                      {/* Original attachments */}
                      {(viewPetitionData?.image_url?.length > 0 ||
                        viewPetitionData?.pdf_url?.length > 0) && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 2 }}>
                            {t('ORIGINAL_ATTACHMENTS')}
                          </Typography>
                          <ViewImageandFile
                            desctiptionsLable={''}
                            title=""
                            descriptions=""
                            images={viewPetitionData?.image_url}
                            files={viewPetitionData?.pdf_url}
                          />
                        </Box>
                      )}

                      {/* Forward Attachments */}
                      {(viewPetitionData?.forword_image?.length > 0 ||
                        viewPetitionData?.forword_pdf?.length > 0 ||
                        viewPetitionData?.forword_description) && (
                        <Box sx={{ mb: 3 }}>
                          <Typography 
                            variant="subtitle1" 
                            fontWeight={600} 
                            sx={{ 
                              mb: 2, 
                              display: 'flex', 
                              alignItems: 'center',
                              color: secondaryColor
                            }}
                          >
                            <ForwardIcon sx={{ mr: 1, fontSize: 20 }} />
                            {t('FORWARD_ATTACHMENTS')}
                          </Typography>
                          <ViewImageandFile
                            desctiptionsLable={`${t('FORWARD')} ${t('DESCRIPTION')}`}
                            title="FORWARD"
                            descriptions={viewPetitionData?.forword_description}
                            images={viewPetitionData?.forword_image}
                            files={viewPetitionData?.forword_pdf}
                          />
                        </Box>
                      )}

                      {/* Resolved Attachments */}
                      {(viewPetitionData?.resolved_image?.length > 0 ||
                        viewPetitionData?.resolved_pdf?.length > 0 ||
                        viewPetitionData?.resolved_description) && (
                        <Box sx={{ mb: 3 }}>
                          <Typography 
                            variant="subtitle1" 
                            fontWeight={600} 
                            sx={{ 
                              mb: 2, 
                              display: 'flex', 
                              alignItems: 'center',
                              color: 'success.main'
                            }}
                          >
                            <DoneAllIcon sx={{ mr: 1, fontSize: 20 }} />
                            {t('RESOLVED_ATTACHMENTS')}
                          </Typography>
                          <ViewImageandFile
                            desctiptionsLable={`${t('RESOLVED')} ${t('DESCRIPTION')}`}
                            title="RESOLVED"
                            descriptions={viewPetitionData?.resolved_description}
                            images={viewPetitionData?.resolved_image}
                            files={viewPetitionData?.resolved_pdf}
                          />
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Paper>

      {/* Modals */}
      <ForwardModal
        setOpen={setForwardModal}
        open={forwardModal}
        modalType={petitionType}
        petitionId={viewPetitionData?.id}
        handleNavigate={handleNavigate}
        viewPetitionData={viewPetitionData}
      />
      <ViewPetitionLogs
        setOpen={setLogModal}
        open={logModal}
        petitionId={viewPetitionData?.id}
      />
    </Box>
  );
};

export default ViewPetition;