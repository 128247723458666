import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import ImagePickerModal from '../../../common/ui/ImagePickerModal';
import {
	savePartieInProgress,
	updatePartyInProgress,
} from '../../../store/parties/_parties.slice';

const PartieForm = ({
	onSubmit = () => false,
	isSubmitting = false,
	defaultValues = {},
	type,
}: {
	onSubmit?: (a: any) => void;
	isSubmitting?: boolean;
	defaultValues?: any;
	type?: any;
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue,
		register,
	} = useForm({
		defaultValues: {
			name: defaultValues?.data?.name || '',
			logo_url: defaultValues?.data?.logo_url || '',
		},
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const profileImage = watch('logo_url');

	const processImage = (data: any, file: any) => {
		try {
			// const imgType = uploadType;
			const imageBase64 = data;
			const fileName = file?.target?.files?.[0]?.name;

			const generateUniqueFileName = () => {
				const timestamp = Date.now();
				const randomId = Math.random().toString(36).substring(2, 8);
				return `image_${timestamp}_${randomId}.jpg`;
			};
			const fileNames = generateUniqueFileName();

			if (imageBase64 || fileName) {
				console.log('profileImage', profileImage);
				setValue('logo_url', data);
				//   if (imgType === 'PROFILE_PHOTO') {
				//     dispatch(
				//       uploadProfilePhotoRequest({
				//         imgType,
				//         imageBase64,
				//         fileName: fileName || fileNames,
				//       } as any)
				//     );
				//   } else if (imgType === 'SIGNATURE') {
				//     dispatch(
				//       uploadSignaturePhotoRequest({
				//         imgType,
				//         imageBase64,
				//         fileName: fileName || fileNames,
				//       } as any)
				//     );
				//   }

				setOpen(false);
			}
		} catch (e) {
			// console.log(e);
		}
	};
	const handleImage = () => {
		setOpen(true);
	};
	const finalSubmit = (data: any) => {
		if (type === 'edit') {
			const handleSuccess = () => {
				navigate(SUPER_ADMIN_ROUTES.PARTIES);
			};

			const payload: any = {
				id: defaultValues.data.id,
				name: data.name,
				logo_url: data.logo_url,
				handleSuccess,
			};
			dispatch(updatePartyInProgress(payload));
		} else {
			const handleSuccess = () => {
				navigate(SUPER_ADMIN_ROUTES.PARTIES);
			};
			const payload: any = {
				data: data,
				handleSuccess,
			};
			dispatch(savePartieInProgress(payload));
		}
	};
	async function pdfUrlToBase64(pdfUrl: any) {
		const response = await fetch(pdfUrl);
		const blob = await response.blob();
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = (reader.result as string)?.split(',')[1];
				resolve(base64data);
			};
			reader.onerror = () => {
				reject(reader.error);
			};
		});
	}
	useEffect(() => {
		async function fetchPdfData() {
			try {
				console.log(
					'defaultValues?.data?.logo_url',
					defaultValues?.data?.logo_url
				);
				const pdfDataPromises: any = await pdfUrlToBase64(
					defaultValues?.data?.logo_url
				);
				console.log('pdfDataPromises', pdfDataPromises);
				setValue('logo_url', pdfDataPromises);
			} catch (error) {
				console.error('Error:', error);
			}
		}
		if (defaultValues?.data?.logo_url) {
			fetchPdfData();
		}
	}, [defaultValues?.data?.logo_url]);

	return (
		<form onSubmit={handleSubmit(finalSubmit)}>
			<Grid container spacing={2}>
				<Grid item sm={12}>
					<FormInput
						control={control}
						name="name"
						label={t('NAME')}
						rules={{
							required: { value: true, message: t('PLEASE_ENTER_NAME') },
						}}
						errors={errors}
					/>
				</Grid>
			</Grid>
			<Grid className="logo_grid">
				{profileImage && (
					<>
						{' '}
						<img
							src={
								profileImage && `data:image/png/jpg/jpeg;base64,${profileImage}`
							}
							className="profile-img-log"
						/>
					</>
				)}

				<CustomButton
					text={t('UPLOAD_LOGO')}
					type="button"
					startIconClassName="photo-icon f-40 "
					className="custom-button-photo mb-25"
					onClick={handleImage}
				/>
			</Grid>
			<Grid container justifyContent={'center'}>
				<CustomButton
					type="submit"
					text={t('SUBMIT')}
					className=" px-10 py-5 my-20 add-donation-button"
					fontSize={14}
					color={'primary'}
					loading={isSubmitting}
				/>

				<FormInput
					name="logo_url"
					type="text"
					placeholder="First Name.."
					control={control}
					className="input-from"
					hidden
					rules={{
						required: {
							value: true,
							message: t('PLEASE_UPLOAD_LOGO'),
						},
					}}
					inputProps={{
						...register('logo_url', {}),
					}}
					errors={errors}
				/>
			</Grid>

			<ImagePickerModal
				open={open}
				setOpen={setOpen}
				handleImage={(data: any, file: any) => processImage(data, file)}
				enableCropping
			/>
		</form>
	);
};

export default PartieForm;
