
import { Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import SvgConverter from '../SvgConverter';

interface IconPropsInterface {
  width?: number | undefined;
  height?: number | undefined;
  color?: string | undefined;
}

interface LabelPropsInterface {
  color?: string | undefined;
  fontSize?: number | undefined;
  fontWeight?: number | undefined;
  marginTop?: any;
}

interface PropsInterface {
  Icon?: any;
  label?: string;
  spacing?: number;
  iconProps?: IconPropsInterface | undefined;
  labelProps?: LabelPropsInterface | undefined;
  flexDirection?: 'column' | 'row';
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  className?: string | undefined;
  svgClassName?: string | undefined;
  optional?: any;
  tooltip?: string | undefined;
  variant?: 'default' | 'outlined' | 'elevated' | 'subtle';
  backgroundColor?: string | undefined;
  iconBackgroundColor?: string | undefined;
}

const LabelWithIcon = ({
  Icon,
  label,
  spacing = 1,
  iconProps,
  labelProps,
  flexDirection = 'row',
  justifyContent = 'center',
  className,
  svgClassName,
  optional,
  tooltip,
  variant = 'default',
  backgroundColor,
  iconBackgroundColor,
}: PropsInterface) => {
  const theme = useTheme();

  const getContainerStyles = () => {
    switch (variant) {
      case 'outlined':
        return {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '8px 12px',
          backgroundColor: backgroundColor || 'transparent',
        };
      case 'elevated':
        return {
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
          borderRadius: '8px',
          padding: '8px 12px',
          backgroundColor: backgroundColor || theme.palette.background.paper,
        };
      case 'subtle':
        return {
          backgroundColor: backgroundColor || theme.palette.action.hover,
          borderRadius: '8px',
          padding: '8px 12px',
        };
      default:
        return {
          backgroundColor: backgroundColor || 'transparent',
          padding: '4px 0',
        };
    }
  };


  const getIconContainerStyles = () => {
    if (flexDirection === 'row') {
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: iconProps?.width || 24,
        height: iconProps?.height || 24,
        backgroundColor: iconBackgroundColor || 'transparent',
        borderRadius: iconBackgroundColor ? '50%' : '0',
        padding: iconBackgroundColor ? '6px' : '0',
      };
    }
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: iconProps?.width || 32,
      height: iconProps?.height || 32,
      backgroundColor: iconBackgroundColor || 'transparent',
      borderRadius: iconBackgroundColor ? '50%' : '0',
      padding: iconBackgroundColor ? '8px' : '0',
      marginBottom: '8px',
    };
  };


  const content = (
    <Paper
      elevation={0}
      sx={{
        ...getContainerStyles(),
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: variant !== 'default' ? 'translateY(-2px)' : 'none',
          boxShadow: variant === 'elevated' ? '0 4px 12px rgba(0, 0, 0, 0.12)' : 'none',
        },
      }}
      className={className}
    >
      <Grid
        container
        spacing={spacing}
        flexDirection={flexDirection}
        alignItems="center"
        justifyContent={justifyContent}
        wrap="nowrap"
      >
        {Icon && (
          <Grid item>
            <div style={getIconContainerStyles()}>
              <SvgConverter
                Icon={Icon}
                className={`${svgClassName}`}
                color={iconProps?.color || theme.palette.primary.main}
              />
            </div>
          </Grid>
        )}
        
        <Grid item>
          <Typography
            color={labelProps?.color || theme.palette.text.primary}
            fontSize={labelProps?.fontSize || 14}
            fontWeight={labelProps?.fontWeight || 500}
            marginTop={labelProps?.marginTop}
            sx={{ 
              transition: 'color 0.2s',
              letterSpacing: '0.01em',
            }}
          >
            {label}
          </Typography>
        </Grid>
        
        {optional && (
          <Grid item>
            <Typography
              color={theme.palette.text.secondary}
              fontSize={labelProps?.fontSize || 12}
              fontWeight={400}
              sx={{ 
                marginLeft: '4px',
                opacity: 0.8,
                fontStyle: 'italic'
              }}
            >
              {optional}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );


  return tooltip ? (
    <Tooltip title={tooltip} arrow placement="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default LabelWithIcon;