import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useLocation, useNavigate } from 'react-router-dom';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import CustomButton from '../../../../common/ui/CustomButton';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';

import { useDispatch, useSelector } from 'react-redux';
import {
	savePublicRequest,
	savepublicSuccessData,
	updatePublicRequest,
} from '../../../../store/public/_public.slice';

const AddPublicForm = ({
	editData,
	dataType,
}: {
	editData?: any;
	dataType?: any;
}) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);

	const addPubliscSucccess = useSelector(savepublicSuccessData);
	console.log('editData', editData);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			full_name: editData?.full_name || '',
			email: editData?.email || '',
			phone: editData?.contact || '',
			address: editData?.address || '',
			password: editData?.password || '',
			candidate_id: editData?.candidate_id || '',
		},
	});
	const handleReset = () => {
		setValue('full_name', '');
		setValue('email', '');
		setValue('phone', '');
		setValue('address', '');
		setValue('password', '');
		setValue('candidate_id', '');
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const newPassword = getValues('password');
		if (name === 'confirmPassword' && newPassword !== value) {
			setPasswordMatch(false);
		} else {
			setPasswordMatch(true);
		}
	};
	useEffect(() => {
		const paylod = {
			pageNo: 1,
		};

		// dispatch(getRolesListInProgress(paylod));
	}, []);

	// const { teamMember } = useSelector((state: any) => state);

	// const handleCategory = (value: any) => {
	// 	setValue('role_id', value, {
	// 		shouldValidate: true,
	// 	});
	// };
	const gender = [
		{
			Code: 1,
			Description: 'candidate 1',
			Category: 'candidate 1',
		},
		{
			Code: 2,
			Description: 'candidate 2',
			Category: 'candidate 2',
		},
		{
			Code: 3,
			Description: 'candidate 3',
			Category: 'candidate 3',
		},
		{
			Code: 4,
			Description: 'candidate 4',
			Category: 'candidate 4',
		},

		{
			Code: 9,
			Description: 'Other',
			Category: 'Other',
		},
	];
	const onSubmit = (data: any) => {
  data.id = location.state?.data?.id;
  data.user_id = location.state?.data?.user_id;
  const payload = dataType === 'edit'
    ? {
        public_id: editData?.id,
        data: {
          full_name: data?.full_name,
          email: data?.email,
          contact: data?.phone,
          address: data?.address,
          candidate_id: '7576f6f8-a793-4a98-8341-c5edd8590de3',
        },
      }
    : {
        full_name: data?.full_name,
        email: data?.email,
        contact: data?.phone,
        address: data?.address,
        candidate_id: '7576f6f8-a793-4a98-8341-c5edd8590de3',
      };

  dataType === 'edit'
    ? dispatch(updatePublicRequest(payload as any))
    : dispatch(savePublicRequest(payload as any));
};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log('emial');
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	useEffect(() => {
		if (addPubliscSucccess === 'done') {
			navigate(APP_ROUTES.PUBLIC_LIST);
		}
	}, [addPubliscSucccess]);
	return (
		<Grid className="mt-30">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					// justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.info.dark}
						>
							{location?.state?.data?.type === 'edit'
								? t('EDIT_PUBLIC')
								: t('ADD_PUBLIC')}
						</Typography>
						<CustomButton
							type="button"
							text="Go Back"
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 color-gray"
							onClick={() => {
								navigate(APP_ROUTES.PUBLIC_LIST);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('FULL_NAME')}
							justifyContent="flex-start"
							className="mb-6 "
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="full_name"
							type="text"
							placeholder={`${t('FULL_NAME')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_FULL_NAME'),
								},
							}}
							inputProps={{
								...register('full_name', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('EMAIL')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="email"
							type="text"
							placeholder={`${t('EMAIL')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_EMAIL'),
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: t('INVALID_EMAIL_ADDRESS'),
								},
							}}
							inputProps={{
								...register('email', {}),
								onChange: handleEmailChange,
							}}
							errors={errors}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('MOBILE_NUMBER')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="phone"
							type="text"
							placeholder={`${t('MOBILE_NUMBER')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_MOBILE_NUMBER'),
								},
								maxLength: {
									value: 10,
									message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
								},
								pattern: {
									value: /^[0-9]*$/,
									message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
								},
								minLength: {
									value: 10,
									message: t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'),
								},
							}}
							inputProps={{
								...register('phone', {}),
							}}
							errors={errors}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('PASSWORD')}
							justifyContent="flex-start"
							className="mb-6  "
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="password"
							type="password"
							placeholder={`${t('PASSWORD')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_PASSWORD'),
								},
							}}
							inputProps={{
								...register('password', {}),
							}}
							errors={errors}
						/>
					</Grid> */}
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('CANDIDATE')}
							justifyContent="flex-start"
							className="mb-6 "
							labelProps={{ color: 'black' }}
						/>
						<CustomSelectBox
							options={gender}
							ClassName="form-select"
							placeholder={`${t('CANDIDATE')}...`}
							inputProps={register('candidate_id', {
								required: {
									value: true,
									message: t('PLEASE_SELECT_A_CANDIDATE'),
								},
							})}
							name={'candidate_id'}
							error={errors}
							defaultValue={location.state?.data?.candidate_id}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('ADDRESS')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							// hideCountButton
							name="address"
							type="text"
							placeholder={`${t('ADDRESS')}...`}
							control={control}
							className="input-from-text"
							multipleLine
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_A_ADDRESS'),
								},
							}}
							inputProps={{
								...register('address', {}),
							}}
							errors={errors}
						/>
					</Grid>

					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={t('RESET')}
							color={'primary'}
							className="p-12 mx-10 color-gray"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text={t('SUBMIT')}
							color={'warning'}
							disabled={!passwordMatch}
							className="p-12"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AddPublicForm;
